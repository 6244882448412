import { RolesEnum } from '../users/roles/enums/roles';

export class AuthUser {
  UserID: string | number;
  UserType: string;
  FullName: string;
  ImagePath: string;
  ObjectValue: number;
  ObjectType: string;
  LoginStatusMessage: string;
  SuccessLogin: boolean;
  Token: string;
  RefreshToken: string;
  UserRoles: RolesEnum[];
  UserState: string;
  expiration: string;
  IsMobileVerfied: boolean;
  IsHaveActiveSla: boolean;
  TfAuthEnabled: boolean;
  AllowVoiceRecognition: boolean;
  ForTrainning: boolean;
  IsTrainer: boolean;
}
