<div class="content-grid">
  <div class="app-hub-content">
    <div class="app-sidebar-container full-width">
      <!-- Content page -->
      <div class="app-content-wrap">
        <!-- Header page -->
        <section class="viewer-header has-shadow bg-white">
          <div class="px-2 m-0">
            <span>{{caseNumber}}</span>
            <h1>{{patientName}}</h1>
          </div>
          <div class="ml-auto d-flex">
            <button mat-raised-button color="primary" class="small-btn w-100 my-3" (click)="openViewerSelector()"
              *ngIf="recordsData.casesCount > 0">
              View Multiple Slides
            </button>
          </div>
        </section>

        <div class="app--body-container">
          <nav class="navbar head-nav">
            <h2>Slides</h2>
            <span class="count-page" *ngIf='!dataLoading'>
              <strong>{{recordsData.casesCount}}</strong> Slides
            </span>
          </nav>
          <div class="page">
            <div *ngIf='dataLoading' class="dataLoading">
              <mat-spinner class="m-auto" diameter="50"></mat-spinner>
            </div>

            <ng-container *ngIf='!dataLoading'>
              <div *ngIf="recordsData.pagedData; else dataEmpty">
                <div class="table-responsive" *ngIf="recordsData.pagedData.length; else dataEmpty">

                  <table class="table table-primary">
                    <thead>
                      <tr>
                        <th>Slide No.</th>
                        <th>Reference Number</th>
                        <th>Stain type</th>
                        <th>Slide Specimen Number</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let record of recordsData.pagedData; let i = index">
                        <td>
                          <h5>
                            #{{(filterModel.PageSize * (filterModel.PageNumber - 1)) + i + 1}}
                          </h5>
                        </td>
                        <td>
                          {{record.generatedSlideReference}}
                        </td>
                        <td>
                          {{record.stainType.name}}
                        </td>
                        <td>
                          {{record.slideSpecimenNumber?record.slideSpecimenNumber:'__'}}
                        </td>
                        <td>
                          <a [routerLink]="['/slideListShare',filterModel.caseIdType, filterModel.CaseId, record.slideId]"
                            [queryParams]="{slide: record.slideId}" mat-button color="primary"
                            class="btn_link_with_icon p-0">
                            View slide
                            <i class="material-icons">
                              launch
                            </i>
                          </a>
                        </td>
                      </tr>
                    </tbody>

                  </table>
                </div>

                <div class="pagination-container">
                  <mat-paginator *ngIf="pageCount > 1" [length]="recordsData.casesCount" [pageSize]="filterModel.PageSize"
                    [pageSizeOptions]="[5, 10, 20]" (page)="getAll($event)" [pageIndex]="pageIndex">
                  </mat-paginator>
                </div>

              </div>
            </ng-container>
            <ng-template #dataEmpty>
              <app-empty-state [title]='"No Data Found"'></app-empty-state>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
