import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthUser } from '../models/auth/AuthUser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RolesEnum } from '../models/users/roles/enums/roles';
import { NavigationService } from './navigation.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<AuthUser> =
    new BehaviorSubject<AuthUser>(null);
  public authUser: any;

  skipLinks = [
    // {
    //   keys: [
    //     '/admin/requests/referred',
    //     '/admin/requests/all'
    //   ],
    //   value: '/all-requests'
    // },
    {
      keys: ['/admin/cases'],
      value: '/pathology-cases',
    },
    // {
    //   keys: [
    //     '/admin/requests/representative-requests-list'
    //   ],
    //   value: '/pickup-delivery'
    // },
    // {
    //   keys: ['/admin/cases'],
    //   value: '/quality-cases'
    // },
    {
      keys: ['/admin/cases/RCasesLAB', '/admin/cases/LabCases'],
      value: '/admin/cases/MainLabCases',
    },
    // {
    //   keys: ['/admin/sla/pathologists'],
    //   value: '/all-sla'
    // },
    // {
    //   keys: ['/admin/payments/incoming-outgoing'],
    //   value: '/all-payments'
    // },
    // {
    //   keys: ['/admin/requests/referred'],
    //   value: '/all-scan-requests'
    // },
    {
      keys: ['/admin/labs'],
      value: '/admin/our-labs',
    },
    // {
    //   keys: ['/admin/requests/referred'],
    //   value: '/all-lab-requests'
    // }
  ];

  viewsRoles = {
    // '/admin/dashboard': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.LabAdmin,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.HospitalAdmin,
    //   RolesEnum.ClinicAdmin
    // ],
    '/admin/all-Pathologists': [RolesEnum.SuperAdmin, RolesEnum.VLAdmin, , RolesEnum.LabAdmin],
    '/admin/Pathologists/All-Pathologists-List/Trainees': [RolesEnum.SuperAdmin],
    '/admin/Pathologists/All-Pathologists-List/Trainers': [RolesEnum.SuperAdmin],
    '/admin/Pathologists/VL-Pathologist-List/Trainees': [RolesEnum.SuperAdmin],
    '/admin/Pathologists/VL-Pathologist-List/Trainers': [RolesEnum.SuperAdmin],
    '/admin/Pathologists/VL-Pathologists/Trainees': [RolesEnum.VLAdmin],
    '/admin/Pathologists/VL-Pathologists/Trainers': [RolesEnum.VLAdmin],
    '/admin/Pathologists/Requests': [RolesEnum.SuperAdmin],
    '/admin/Pathologists/Lab-Pathologists-List/Trainees': [RolesEnum.LabAdmin],
    '/admin/Pathologists/Lab-Pathologists-List/Trainers': [RolesEnum.LabAdmin],

    '/admin/all-users': [RolesEnum.SuperAdmin, RolesEnum.VLAdmin],
    '/admin/users/labs-radiology': [RolesEnum.SuperAdmin],
    // '/admin/users/hospital': [RolesEnum.SuperAdmin],
    // '/admin/users/clinic': [RolesEnum.SuperAdmin],
    // '/admin/users/scanning-centers': [RolesEnum.SuperAdmin],
    '/admin/users/virtual-lab': [RolesEnum.SuperAdmin, RolesEnum.VLAdmin],

    // '/admin/hospitals': [RolesEnum.SuperAdmin],
    // '/admin/clinics': [RolesEnum.SuperAdmin],
    // '/admin/sla': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabSupervisor,
    //   RolesEnum.MatchMaker,
    //   RolesEnum.LabTechnician,
    //   RolesEnum.LabAccountant,
    //   RolesEnum.LabRepresentative,
    //   RolesEnum.Pathologist,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.VLMatchMaker,
    //   RolesEnum.VLPathologist,
    //   RolesEnum.VLSupervisor,
    //   RolesEnum.VLTechnician,
    //   RolesEnum.VLRepresentative,
    //   RolesEnum.VLAccountant,
    //   RolesEnum.ScanningCenterAdmin,
    //   RolesEnum.SCTechnician,
    //   RolesEnum.ScanningCenterAccountant,
    //   RolesEnum.ScanningCenterRepresentative,
    //   RolesEnum.HospitalAdmin,
    //   RolesEnum.HospitalRepresentative,
    //   RolesEnum.HospitalCoordinator,
    //   RolesEnum.HospitalAccountant,
    //   RolesEnum.ClinicAdmin,
    //   RolesEnum.ClinicAccountant,
    //   RolesEnum.ClinicRepresentative,
    //   RolesEnum.ClinicCoordinator
    // ],
    '/admin/labs': [
      RolesEnum.SuperAdmin,
      RolesEnum.HospitalAdmin,
      RolesEnum.ClinicAdmin,
    ],
    '/admin/our-labs': [RolesEnum.HospitalAdmin, RolesEnum.ClinicAdmin],
    // '/admin/scanning-centers': [RolesEnum.SuperAdmin],

    '/admin/labProfile': [
      RolesEnum.LabAdmin,
      // RolesEnum.Pathologist,
      RolesEnum.LabTechnician,
      RolesEnum.LabAccountant,
      RolesEnum.LabRepresentative,
      RolesEnum.LabSupervisor,
      RolesEnum.MatchMaker,
      RolesEnum.LabQualityManager,
      RolesEnum.LabTechnologist,
    ],
    '/admin/vlProfile': [
      RolesEnum.SuperAdmin,
      RolesEnum.VLAdmin,
      RolesEnum.VLMatchMaker,
      RolesEnum.VLPathologist,
      RolesEnum.VLSupervisor,
      RolesEnum.VLTechnician,
      RolesEnum.VLRepresentative,
      RolesEnum.VLabQualityManager,
      RolesEnum.VLAccountant,
      RolesEnum.VLabTechnologist,
    ],
    '/admin/hospitalProfile': [
      RolesEnum.HospitalAdmin,
      RolesEnum.HospitalRepresentative,
      RolesEnum.HospitalCoordinator,
      RolesEnum.HospitalAccountant,
    ],
    '/admin/clinicProfile': [
      RolesEnum.ClinicAdmin,
      RolesEnum.ClinicRepresentative,
      RolesEnum.ClinicCoordinator,
      RolesEnum.ClinicAccountant,
    ],
    '/admin/users/labStaff': [RolesEnum.LabAdmin],
    '/admin/users/hospitalStaff': [RolesEnum.HospitalAdmin],
    '/admin/users/clinicStaff': [RolesEnum.ClinicAdmin],
    '/admin/users/statistics-performance-indicator': [
      RolesEnum.SuperAdmin,
      RolesEnum.VLAdmin,
      RolesEnum.LabAdmin,
    ],

    '/admin/scProfile': [
      RolesEnum.ScanningCenterAdmin,
      RolesEnum.SCTechnician,
      RolesEnum.ScanningCenterAccountant,
      RolesEnum.ScanningCenterRepresentative,
    ],
    '/admin/users/scStaff': [RolesEnum.ScanningCenterAdmin],

    // '/admin/branches/lab': [RolesEnum.MainLabAdmin],
    '/admin/branches/scanning-center': [RolesEnum.ScanningCenterMainAdmin],

    '/admin/all-configuration': [
      RolesEnum.SuperAdmin,
      RolesEnum.MainLabAdmin,
      RolesEnum.LabAdmin,
      RolesEnum.VLAdmin,
      RolesEnum.VLAccountant,
      RolesEnum.HospitalAdmin,
      RolesEnum.ClinicAdmin,
    ],
    '/admin/configuration/tissues': [RolesEnum.SuperAdmin],
    '/admin/configuration/entity-tissues': [
      RolesEnum.HospitalAdmin,
      RolesEnum.ClinicAdmin,
    ],
    '/admin/configuration/subspecialty': [RolesEnum.SuperAdmin],
    // '/admin/configuration/services-fees': [RolesEnum.SuperAdmin, RolesEnum.VLAccountant],
    '/admin/configuration/stain-types': [RolesEnum.SuperAdmin],
    '/admin/configuration/reports': [
      RolesEnum.SuperAdmin,
      RolesEnum.MainLabAdmin,
      RolesEnum.LabAdmin,
      RolesEnum.VLAdmin,
    ],
    // '/admin/configuration/storage-calculator': [RolesEnum.SuperAdmin],
    '/admin/configuration/media-center': [],

    // Cases
    '/admin/cases/details': [
      RolesEnum.SuperAdmin,
      RolesEnum.LabAdmin,
      RolesEnum.LabSupervisor,
      RolesEnum.MatchMaker,
      RolesEnum.LabTechnician,
      RolesEnum.Pathologist,
      RolesEnum.VLAdmin,
      RolesEnum.VLMatchMaker,
      RolesEnum.VLPathologist,
      RolesEnum.VLSupervisor,
      RolesEnum.VLTechnician,
      RolesEnum.LabTechnologist,
      RolesEnum.VLabTechnologist,
      RolesEnum.LabQualityManager,
      RolesEnum.VLabQualityManager,
    ],
    '/admin/cases': [
      RolesEnum.LabAdmin,
      RolesEnum.LabSupervisor,
      RolesEnum.MatchMaker,
      // RolesEnum.Pathologist,
      RolesEnum.LabTechnician,
      RolesEnum.VLAdmin,
      RolesEnum.HospitalAdmin,
      RolesEnum.HospitalCoordinator,
      RolesEnum.ClinicAdmin,
      RolesEnum.ClinicCoordinator,
      RolesEnum.LabTechnologist,
    ],
    '/admin/cases/report': [
      RolesEnum.SuperAdmin,
      RolesEnum.LabAdmin,
      RolesEnum.LabSupervisor,
      RolesEnum.MatchMaker,
      RolesEnum.Pathologist,
      RolesEnum.LabTechnician,
      RolesEnum.VLAdmin,
      RolesEnum.VLPathologist,
      RolesEnum.VLMatchMaker,
      RolesEnum.VLSupervisor,
      RolesEnum.VLTechnician,
    ],
    '/admin/cases/MainLabCases': [RolesEnum.MainLabAdmin],
    '/admin/cases/LabCases': [
      RolesEnum.LabAdmin,
      RolesEnum.LabTechnician,
      RolesEnum.LabSupervisor,
      RolesEnum.MatchMaker,
      RolesEnum.LabTechnologist,
    ],
    // '/admin/cases/BranchsCases': [
    //   RolesEnum.MainLabAdmin
    // ],
    /* '/admin/cases/SCCases': [
      RolesEnum.ScanningCenterMainAdmin
    ],
    '/admin/cases/SCBranchsCases': [
      RolesEnum.ScanningCenterMainAdmin,
    ], */
    '/admin/cases/VLCases': [
      RolesEnum.VLAdmin,
      RolesEnum.SuperAdmin,
      RolesEnum.VLTechnician,
      RolesEnum.VLSupervisor,
      RolesEnum.VLPathologist,
      RolesEnum.VLMatchMaker,
      RolesEnum.VLabTechnologist,
    ],
    '/admin/cases/RCasesVL': [
      RolesEnum.VLAdmin,
      RolesEnum.SuperAdmin,
      RolesEnum.VLTechnician,
      RolesEnum.VLSupervisor,
      RolesEnum.VLPathologist,
      RolesEnum.VLMatchMaker,
      RolesEnum.VLabTechnologist,
    ],
    '/admin/cases/RCases': [RolesEnum.SuperAdmin],
    '/admin/cases/RCasesLAB': [
      RolesEnum.LabAdmin,
      RolesEnum.LabTechnician,
      RolesEnum.LabSupervisor,
      RolesEnum.MatchMaker,
      RolesEnum.LabTechnologist,
    ],
    '/admin/cases/LabsCases': [RolesEnum.SuperAdmin],
    /* '/admin/cases/RCasesSC': [
      RolesEnum.SuperAdmin
    ], */

    '/viewer': [
      RolesEnum.SuperAdmin,
      RolesEnum.LabAdmin,
      RolesEnum.LabTechnician,
      RolesEnum.MatchMaker,
      RolesEnum.LabSupervisor,
      RolesEnum.Pathologist,
      RolesEnum.ScanningCenterAdmin,
      RolesEnum.SCTechnician,
      RolesEnum.VLAdmin,
      RolesEnum.VLTechnician,
      RolesEnum.VLMatchMaker,
      RolesEnum.VLPathologist,
      RolesEnum.VLSupervisor,
      RolesEnum.HospitalAdmin,
      RolesEnum.HospitalCoordinator,
      RolesEnum.ClinicAdmin,
      RolesEnum.ClinicCoordinator,
    ],

    // Dropdowns
    '/pathology-cases': [
      // RolesEnum.MainLabAdmin,
      // RolesEnum.Pathologist,
      // RolesEnum.LabAdmin,
      // RolesEnum.LabTechnician,
      // RolesEnum.MatchMaker,
      // RolesEnum.LabSupervisor,
      // RolesEnum.VLAdmin,
      RolesEnum.SuperAdmin,
      // RolesEnum.VLTechnician,
      // RolesEnum.VLMatchMaker,
      // RolesEnum.VLSupervisor,
      // RolesEnum.VLPathologist,
      // RolesEnum.LabTechnologist,
      // RolesEnum.VLabTechnologist,
    ],
    '/admin/cases/PathOtherCases': [RolesEnum.Pathologist],
    '/admin/cases/slideCases': [RolesEnum.Pathologist],
    '/admin/cases/GrossingCases': [RolesEnum.Pathologist],
    '/admin/cases/MicroscopeDiagnosisCases': [RolesEnum.Pathologist],

    // QualityManager
    '/quality-cases': [
      RolesEnum.LabQualityManager,
      RolesEnum.VLabQualityManager,
    ],
    '/admin/cases/QualityCases': [
      RolesEnum.LabQualityManager,
      RolesEnum.VLabQualityManager,
    ],
    '/admin/cases/QualityCasesRef': [
      RolesEnum.LabQualityManager,
      RolesEnum.VLabQualityManager,
    ],
    '/trainingCourses': [RolesEnum.SuperAdmin, RolesEnum.Trainer, RolesEnum.LabAdmin],
    '/courses': [RolesEnum.Trainee,RolesEnum.Trainer],
    '/live': [RolesEnum.Trainer, RolesEnum.Trainee],
    /* '/referred-cases': [
      RolesEnum.ScanningCenterMainAdmin
    ], */
    // '/all-sla': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabAccountant,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.VLAccountant,
    //   RolesEnum.HospitalAdmin,
    //   RolesEnum.HospitalAccountant,
    //   RolesEnum.ClinicAdmin,
    //   RolesEnum.ClinicAccountant,
    //   RolesEnum.ScanningCenterAdmin,
    //   RolesEnum.ScanningCenterAccountant
    // ],
    // '/admin/sla/pathologists': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabAccountant,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.VLAccountant,
    //   RolesEnum.VLPathologist,
    //   RolesEnum.Pathologist
    // ],
    // '/admin/sla/hospitalsclinics': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabAccountant,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.VLAccountant,
    //   RolesEnum.ScanningCenterAdmin,
    //   RolesEnum.ScanningCenterAccountant
    // ],
    // '/admin/sla/scanningCenters': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabAccountant,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.VLAccountant,
    //   RolesEnum.HospitalAdmin,
    //   RolesEnum.HospitalAccountant,
    //   RolesEnum.ClinicAdmin,
    //   RolesEnum.ClinicAccountant
    // ],
    // '/admin/sla/labs': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.HospitalAdmin,
    //   RolesEnum.HospitalAccountant,
    //   RolesEnum.ClinicAdmin,
    //   RolesEnum.ClinicAccountant,
    //   RolesEnum.ScanningCenterAdmin,
    //   RolesEnum.ScanningCenterAccountant
    // ],
    // '/admin/sla/otherlabs': [
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabAccountant,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.VLAccountant
    // ],
    // '/admin/sla/otherentity': [
    //   RolesEnum.SuperAdmin
    // ],
    // '/admin/sla/entitywithentity': [
    //   RolesEnum.SuperAdmin
    // ],

    // '/admin/requests/all': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.HospitalAdmin,
    //   RolesEnum.HospitalCoordinator,
    //   RolesEnum.ClinicAdmin,
    //   RolesEnum.ClinicCoordinator,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.VLTechnician,
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabTechnician
    // ],
    // '/admin/requests/referred': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.VLTechnician,
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabTechnician,
    //   RolesEnum.ScanningCenterAdmin,
    //   RolesEnum.SCTechnician
    // ],
    // '/all-requests': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabTechnician,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.VLTechnician
    // ],
    // '/all-scan-requests': [
    //   RolesEnum.ScanningCenterMainAdmin,
    // ],
    // '/all-lab-requests': [
    //   RolesEnum.MainLabAdmin,
    // ],
    // '/admin/requests/branches': [
    //   RolesEnum.ScanningCenterMainAdmin,
    // ],
    // '/admin/requests/branches/all': [
    //   RolesEnum.MainLabAdmin,
    // ],
    // '/admin/requests/branches/referred': [
    //   RolesEnum.MainLabAdmin,
    // ],
    // '/admin/requests/details': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabRepresentative,
    //   RolesEnum.LabTechnician,
    //   RolesEnum.SCTechnician,
    //   RolesEnum.ScanningCenterAdmin,
    //   RolesEnum.ScanningCenterRepresentative,
    //   RolesEnum.HospitalAdmin,
    //   RolesEnum.HospitalCoordinator,
    //   RolesEnum.ClinicAdmin,
    //   RolesEnum.ClinicCoordinator,
    //   RolesEnum.VLRepresentative,
    //   RolesEnum.VLTechnician,
    //   RolesEnum.VLAdmin
    // ],
    // '/admin/requests/pickup-delivery': [
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabRepresentative,
    //   RolesEnum.LabTechnician,
    //   RolesEnum.SCTechnician,
    //   RolesEnum.ScanningCenterAdmin,
    //   RolesEnum.ScanningCenterRepresentative,
    //   RolesEnum.VLRepresentative,
    //   RolesEnum.VLTechnician,
    //   RolesEnum.VLAdmin
    // ],
    // Dropdowns
    '/pickup-delivery': [
      RolesEnum.LabAdmin,
      RolesEnum.LabRepresentative,
      RolesEnum.LabTechnician,
      RolesEnum.VLRepresentative,
      RolesEnum.VLTechnician,
      RolesEnum.VLAdmin,
    ],
    // '/admin/requests/representative-requests-list': [
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabRepresentative,
    //   RolesEnum.LabTechnician,
    //   RolesEnum.SCTechnician,
    //   RolesEnum.ScanningCenterAdmin,
    //   RolesEnum.ScanningCenterRepresentative,
    //   RolesEnum.VLRepresentative,
    //   RolesEnum.VLTechnician,
    //   RolesEnum.VLAdmin
    // ],
    // '/admin/requests/representative-requests-containers': [
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabRepresentative,
    //   RolesEnum.LabTechnician,
    //   RolesEnum.VLRepresentative,
    //   RolesEnum.VLTechnician,
    //   RolesEnum.VLAdmin
    // ],
    // '/all-payments': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.VLAccountant,
    // ],
    // '/admin/payments/TransactionsBetweenEntities': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.VLAccountant
    // ],
    // '/admin/payments/VLTransactions': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.VLAccountant,
    // ],
    // '/admin/payments/HistoFeesTransactions': [
    //   RolesEnum.SuperAdmin,
    //   RolesEnum.VLAccountant
    // ],
    // '/admin/payments/incoming-outgoing': [
    //   RolesEnum.LabAdmin,
    //   RolesEnum.LabAccountant,
    //   RolesEnum.ScanningCenterAdmin,
    //   RolesEnum.ScanningCenterAccountant,
    //   RolesEnum.VLAdmin,
    //   RolesEnum.VLAccountant,
    //   RolesEnum.HospitalAdmin,
    //   RolesEnum.HospitalAccountant,
    //   RolesEnum.ClinicAdmin,
    //   RolesEnum.ClinicAccountant,
    //   RolesEnum.Pathologist,
    // ],

    ////////////////////
    '/login': [],
    '/verifiy-otp': [],
    '/admin/myprofile': [],
    '/admin/changepassword': [],
    '/admin/notAuthorized': [],
    '/admin': [],
    '/': [],
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private NavService: NavigationService
  ) {
    const ls = JSON.parse(localStorage.getItem('authUser')) as AuthUser;
    if (ls && ls.Token) {
      this.currentUserSubject.next(ls);
      this.AuthUserNavMenu();
    }

    window.addEventListener('storage', () => {
      // When local storage changes, dump the list to
      const authUser = JSON.parse(localStorage.getItem('authUser')) as AuthUser;
      if (
        (this.currentUserValue &&
          authUser &&
          this.currentUserValue.Token !== authUser.Token) ||
        (this.currentUserValue && !authUser) ||
        (!this.currentUserValue && authUser)
      ) {
        location.reload();
      }
    });
  }

  public get currentUserValue(): AuthUser {
    return this.currentUserSubject.value;
  }

  AuthUserNavMenu(list = null) {
    const authUserMenu = list || [...this.NavService.defaultMenu];
    authUserMenu.map((element) => {
      element.canAccess = this.canAccess(element.state);
      const indexFound = this.skipLinks.findIndex(
        (item) => item.value === element.state
      );
      if (indexFound > -1 && element.canAccess) {
        this.skipLinks[indexFound].keys.map((key) => {
          const indexItem = authUserMenu.findIndex(
            (item) => item.state === key
          );
          if (indexItem > -1) {
            authUserMenu.splice(indexItem, 1);
          }
        });
      }
      if (element.sub && element.sub.length) {
        this.AuthUserNavMenu(element.sub);
      }
    });
    this.NavService.menuItems.next(authUserMenu);
  }

  canAccess(link, gard = false) {
    let canAccess = false;
    if (this.currentUserValue) {
      for (const [key, value] of Object.entries(this.viewsRoles)) {
        if (!value.length && key === link) {
          canAccess = true;
        } // no role need
        // gard
        let gardPathAccess = false;
        if (gard) {
          gardPathAccess = link && key && link.includes(key);
        }
        if (!canAccess && (key === link || gardPathAccess)) {
          // link && key && link.includes(key)
          this.currentUserValue.UserRoles.forEach((role) => {
            if (value.includes(role) && !canAccess) {
              canAccess = true;
            }
          });
        }
      }
    }
    return canAccess;
  }

  login(data) {
    return this.http
      .post<AuthUser>(`${environment.apiUrl}/Authenticate/login`, data)
      .pipe(
        map((user) => {
          if (user && user.Token) {
            this.currentUserSubject.next(user);
            localStorage.setItem('authUser', JSON.stringify(user));
            this.AuthUserNavMenu();
            return user;
          }
          return false;
        })
      );
  }
  RefeshUserToken(reload = false) {
    return this.http
      .post<AuthUser>(`${environment.apiUrl}/Authenticate/refresh-token`, {})
      .pipe(
        map((returnUser) => {
          if (returnUser) {
            this.currentUserSubject.next(returnUser);
            localStorage.setItem('authUser', JSON.stringify(returnUser));
            if (this.router.url !== '/admin/myprofile') {
              location.reload();
            } else if (reload && this.router.url !== '/admin/myprofile') {
              location.reload();
            }
          } else {
            this.logout();
          }
        })
      );
  }
  logout() {
    this.clearUserData();
    this.router.navigate(['/login']).then(() => location.reload());
  }
  clearUserData() {
    localStorage.removeItem('concurelUser');
    localStorage.removeItem('authUser');
    this.currentUserSubject.next(null);
  }
  checkActivationLink(userId, token) {
    return this.http.post(`${environment.apiUrl}/Authenticate/VerifyToken`, {
      userId,
      token,
    });
  }
  VerifyResetToken(userId, token) {
    return this.http.post(
      `${environment.apiUrl}/Authenticate/VerifyResetToken`,
      { userId, token }
    );
  }
  changeUserNamePassword(data) {
    return this.http.post(
      `${environment.apiUrl}/Authenticate/ChangeUserNamePassword`,
      data
    );
  }
  forget(type, value) {
    if (type === 'username') {
      return this.http.post(
        `${environment.apiUrl}/Authenticate/ForgetUserName`,
        { Email: value }
      );
    }
    if (type === 'password') {
      return this.http.get(
        `${environment.apiUrl}/Authenticate/GenerateResetPasswordToken?UserName=${value}`
      );
    }
  }
  resetPassword(userID, token, password) {
    return this.http.post(`${environment.apiUrl}/Authenticate/ResetPassword`, {
      userID,
      token,
      password,
    });
  }

  ChangePassword(oldPassword, newPassword) {
    return this.http.post(`${environment.apiUrl}/User/ChangePassword`, {
      oldPassword,
      newPassword,
    });
  }
  confrimEmail(userId, code) {
    return this.http.get(
      `${environment.apiUrl}/User/ConfirmEmail?userId=` +
      userId +
      '&code=' +
      encodeURIComponent(code)
    );
  }
  RegisterUser(obj): any {
    return this.http.post(`${environment.apiUrl}/User/RegisterUser`, obj);
  }

  hasPermission(permission: string, checkOtherRoles = false) {
    if (!this.currentUserValue) {
      return false;
    }
    const accessControls: string[] = this.currentUserValue.UserRoles;
    const accessRolesList = permission.split(',');
    const founded = [];
    accessRolesList.forEach((role) => {
      const found = accessControls.some((control) => control === role);
      if (found) {
        founded.push(role);
      }
    });

    // if (founded.length && accessControls.length) { return true; }
    if (
      founded.length &&
      accessControls.length &&
      checkOtherRoles &&
      founded.length !== accessControls.length
    ) {
      return false;
    } else if (founded.length && accessControls.length) {
      return true;
    } else {
      return false;
    }
  }

  baseRoute = '';
  checkNavForCourses() {
    if (this.currentUserValue) {
      this.baseRoute = this.currentUserValue.TfAuthEnabled ? this.baseRoute : '/admin/myprofile';
      if (this.currentUserValue.ForTrainning && !this.currentUserValue.IsTrainer) {
        this.baseRoute = '/courses';
      } else if (this.currentUserValue.ForTrainning && this.currentUserValue.IsTrainer) {
        this.baseRoute = '/trainingCourses';
      }
      this.router.navigate([this.baseRoute], { replaceUrl: true });
    }
  }
}
