<nav class="navbar head-nav">
  <h2 *ngIf="!dataLoading" [matTooltip]="details.labName" matTooltipClass="wrap-tooltip">
    {{ (isOurProfile || isOurProfileVl) ? 'Educational Center Profile' : details.labName }}
  </h2>
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]='["/"]'>Home</a></li>
    <li class="breadcrumb-item">
      {{
      isOurProfile ? 'Educational Center Profile' :
      hasPermission('HospitalAdmin') || hasPermission('ClinicAdmin')?'Educational Center(s)':'Labs'
      }}
    </li>
  </ol>
</nav>

<div *ngIf='dataLoading' class="dataLoading">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<div class="lab_profile" *ngIf="(!dataLoading && (isOurProfile || isOurProfileVl)) || (!dataLoading && labId)">
  <div class="profile_wrapper_header">
    <div class="profile_inner_left">
      <div class="profile_image">
        <img [src]="details.logo" (error)='details.logo = "assets/images/lab/default_avatar.svg"' />
        <!-- <span>MS</span> -->
      </div>
      <div class="profile_content_left">
        <div class="profile_content_left_name"
          *ngIf="details.labType === 'BRANCH' || details.labType === 'HOSPITAL_LAB' || details.labType === 'CLINIC_LAB'">
          <span>
            {{
            details.labType === 'HOSPITAL_LAB'?'Lab of Hospital:':
            details.labType === 'CLINIC_LAB'?'Lab of Clinic:':'Branch of:'
            }}
          </span>
          <div class="profile_content_left_name_inner">
            <ng-container *ngIf="details.labType !== 'BRANCH'; else labBranch">
              <img [src]="details.entity.logoUrl"
                (error)="details.entity.logoUrl = './assets/images/lab/default_avatar.svg'" alt="branche">
              <h5>{{details.entity.entityName}}</h5>
            </ng-container>
            <ng-template #labBranch>
              <img [src]="details.mainLab.logo"
                (error)="details.mainLab.logo = './assets/images/lab/default_avatar.svg'" alt="branche">
              <h5>{{details.mainLab.name}}</h5>
            </ng-template>
          </div>
        </div>
        <!-- <button appAccessControl accessRoles="SuperAdmin" type="button" class="badge_btn" (click)="openLabForm(true)"
          *ngIf="details.labType === 'MAIN_LAB' && details.branches.length === 0 && (!isOurProfile || !isOurProfileVl)">
          <img src="./assets/images/lab/branches-icon.svg" alt="">
          Add Branch
        </button> -->
        <button appAccessControl accessRoles="SuperAdmin" type="button" class="badge_btn" (click)="showBranches()"
          *ngIf="details.branches?.length > 0">
          <img src="./assets/images/lab/branches-icon.svg" alt="">
          Show Branches
          <img src="./assets/images/icons/arrow-right.svg" class="right_img" alt="">
        </button>
        <h4>{{details.labName}}</h4>
        <div class="create_date">
          Created on: <span>{{details.createdOn | date:'d MMM, y, h:mm a'}}</span>
        </div>
      </div>
    </div>
    <div class="profile_inner_right">
      <button appAccessControl accessRoles="SuperAdmin" mat-icon-button [matTooltip]="'Delete'"
        (click)="deleteRecord(labId)" *ngIf="!isOurProfileVl && details.canDeleteLab">
        <svg width="16" height="20.002" viewBox="0 0 16 20.002">
          <path id="delete-icon"
            d="M4422.4,17833.639a1.853,1.853,0,0,1-1.844-1.785l-.51-10.822a.2.2,0,0,1,.192-.207H4433.9a.194.194,0,0,1,.192.207l-.505,10.822a1.859,1.859,0,0,1-1.844,1.785Zm7.134-11.252v8.129a.616.616,0,1,0,1.231,0v-8.129a.616.616,0,1,0-1.231,0Zm-3.079,0v8.129a.615.615,0,1,0,1.23,0v-8.129a.615.615,0,1,0-1.23,0Zm-3.075,0v8.129a.615.615,0,1,0,1.23,0v-8.129a.615.615,0,1,0-1.23,0Zm-3.692-2.811a.621.621,0,0,1-.618-.627v-1.248a1.551,1.551,0,0,1,1.54-1.562h3.383v-.627a1.864,1.864,0,0,1,1.849-1.875h2.461a1.86,1.86,0,0,1,1.844,1.875v.627h3.383a1.551,1.551,0,0,1,1.54,1.563v1.248a.617.617,0,0,1-.613.627Zm5.536-4.064v.627h3.692v-.627a.616.616,0,0,0-.613-.621h-2.461A.619.619,0,0,0,4425.223,17815.512Z"
            transform="translate(-4419.069 -17813.637)" fill="#616e7c" />
        </svg>
      </button>
      <a class="button_with_icon" appAccessControl accessRoles="SuperAdmin,LabAdmin,VLAdmin,HospitalAdmin,ClinicAdmin"
        mat-raised-button color="primary" (click)="openLabForm(false)">
        <svg width="16" height="16.004" viewBox="0 0 16 16.004">
          <path id="edit-icon"
            d="M5.82,14l-3.63-3.63,8.78-8.78,3.63,3.63ZM1.851,11.011l3.33,3.33L.188,16Zm13.865-6.9-.63.63L11.451,1.1l.63-.63a1.624,1.624,0,0,1,2.3,0l1.338,1.338a1.633,1.633,0,0,1,0,2.3Zm0,0"
            transform="translate(-0.188 0.001)" />
        </svg>
        <span class="value">Edit</span>
      </a>
    </div>
  </div>
  <div class="row">

    <!-- <div class="col-xl-6 col-lg-12 col-md-12 card_info_profile card_info_transactions">
      <div class="card_info_profile_content" appAccessControl
        accessRoles="SuperAdmin,LabAccountant,VLAccountant,LabAdmin,MainLabAdmin,VLAdmin,HospitalAdmin,ClinicAdmin">
        <app-entity-balance [walletDetails]="walletDetails" [isOurProfile]="isOurProfile || isOurProfileVl"
          [entityId]="!isOurProfile && !isOurProfileVl?labId:0"
          [entityType]="!isOurProfile && !isOurProfileVl?'LAB':null"></app-entity-balance>
      </div>
    </div> -->

    <div class="col-xl-12 col-lg-12 col-md-12 card_info_profile card_info_case">
      <div class="card_info_profile_content" appAccessControl
        accessRoles="SuperAdmin,LabAccountant,VLAccountant,LabAdmin,MainLabAdmin,VLAdmin,HospitalAdmin,ClinicAdmin"
        [ngClass]="{'d-flex justify-content-center align-items-center':details.openCasesCount === 0}">
        <app-entity-total-cases [casesCount]="details.casesCount" [openCasesCount]="details.openCasesCount"
          [closedCasesCount]="details.closedCasesCount" [entityName]="details.labName"
          [isOurProfile]="isOurProfile || isOurProfileVl"></app-entity-total-cases>
      </div>
    </div>

    <!-- <div class="col-xl-6 col-lg-12 col-md-12">
      <div class="card_info_profile card_info_transactions" appAccessControl
        accessRoles="SuperAdmin,LabAccountant,VLAccountant,LabAdmin,MainLabAdmin,VLAdmin,HospitalAdmin,ClinicAdmin">
        <div class="card_info_profile_content">
          <ng-container *ngIf="walletDetails && walletDetails.incoming; else walletEmpty">
            <app-entity-incoming [walletDetails]="walletDetails"></app-entity-incoming>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12">
      <div class="card_info_profile card_info_transactions" appAccessControl
        accessRoles="SuperAdmin,LabAccountant,VLAccountant,LabAdmin,MainLabAdmin,VLAdmin,HospitalAdmin,ClinicAdmin">
        <div class="card_info_profile_content">
          <ng-container *ngIf="walletDetails && walletDetails.outgoing; else walletEmpty">
            <app-entity-outgoing [walletDetails]="walletDetails"></app-entity-outgoing>
          </ng-container>
        </div>
      </div>
    </div> -->

    <div class="col-xl-6 col-lg-12 col-md-12 card_info_profile">
      <app-entity-basic-info (verifyMobileEvent)="verifyMobile($event)" (verfiyEmailEvent)="verfiyEmail($event)"
        [record]="details" [entityId]="labId"
        [entityType]="isOurProfileVl ? entityEnumSLA.vl : entityEnumSLA.lab"></app-entity-basic-info>
    </div>

    <div class="col-xl-6 col-lg-12 col-md-12 card_info_profile title-has-btns revert"
      *ngIf="!isOurProfile && !isOurProfileVl">
      <app-users-list-shared [sla]="details.labSLA" [entityType]='"Lab"' [entityId]='labId'>
      </app-users-list-shared>
    </div>

    <ng-container *ngIf="isOurProfile">
      <ng-container *ngTemplateOutlet="communicationMethods"></ng-container>
    </ng-container>

    <!-- <div class="col-xl-6 col-lg-12 col-md-12" *ngIf="!isOurProfileVl">
      <div class="card_info_profile" appAccessControl accessRoles="SuperAdmin,LabAdmin,LabAccountant">
        <div class="card_info_profile_content">
          <app-sla-actions [entityId]="labId?labId:currentUser.ObjectValue" [isProfile]="isOurProfile"
            [entityValue]="'Lab'" [entityType]="'lab'" [sla]="details.labSLA" [lastSLA]="details.lastSLA"
            [entityName]="details.labName">
          </app-sla-actions>
        </div>
      </div>
    </div> -->
    <ng-container *ngIf="!isOurProfile">
      <ng-container *ngTemplateOutlet="communicationMethods"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #communicationMethods>
  <div class="col-xl-6 col-lg-12 col-md-12">
    <app-entity-communication-methods [communicationMethodList]="details.communicationMethod"
      [entityName]="'Educational Center'"></app-entity-communication-methods>
  </div>
</ng-template>

<app-sidenav-over *ngIf="openSideNav" [open]="openSideNav" (closeDetails)="closeSidenav($event)">
  <app-branches-side [type]="'Lab'" (closeDetails)="closeSidenav($event)"
    (deleteBranch)="deleteRecord($event)"></app-branches-side>
</app-sidenav-over>

<!-- <ng-template #walletEmpty>
  <app-empty-state custClass="py-0" [desc]='"The wallet is empty, and no transactions have occurred."'
    [img]="'./assets/images/icons/wallet-icon.svg'"></app-empty-state>
</ng-template> -->