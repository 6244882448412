import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { CourseDetail, CurrentView } from '../models/courses/Courses';
import { AuthenticationService } from './authentication.service';
import { RolesEnum } from '../models/users/roles/enums/roles';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  showContent = new Subject<CurrentView>();
  nextContent = new Subject<boolean>();

  constructor(private http: HttpClient,
    private auth: AuthenticationService) { }

  show(type, ModuleIndex = null, LessonIndex = null) {
    this.showContent.next({ type, ModuleIndex, LessonIndex });
  }

  showNav(type, ModuleIndex = null, LessonIndex = null, isCompleted = false, nextModuleLessonId, level) {
    if (this.hasPermission(RolesEnum.Trainee)) {
      if (isCompleted || nextModuleLessonId == level.id) {
        this.showContent.next({ type, ModuleIndex, LessonIndex });
      }
    } else {
      this.showContent.next({ type, ModuleIndex, LessonIndex });
    }
  }
  hasPermission(permission: string, checkOtherRoles = false) {
    return this.auth.hasPermission(permission, checkOtherRoles);
  }

  getAll(params): any {
    return this.http.get(`${environment.apiUrl}/course/TraineeEnrolledCourse`, {
      params,
    });
  }

  GetModulesByCourseId(id) {
    return this.http.get(
      `${environment.apiUrl}/CourseModule/GetModulesByCourseId?CourseId=${id}`
    );
  }

  GetModuleLessonById(id, isPreview) {
    return this.http.get(
      `${environment.apiUrl}/CourseModule/GetModuleLessonById?ModuleLessonId=${id}&IsPreview=${isPreview}`
    );
  }

  submitAnswers(model) {
    return this.http.post(
      `${environment.apiUrl}/TraineeCourse/SubmitAnswers`,
      model
    );
  }

  ShowAnswers(id) {
    return this.http.get(
      `${environment.apiUrl}/TraineeCourse/ShowAnswers?QuizId=${id}`
    );
  }

  MarkLessonIsCompleted(lessonId, courseId) {
    return this.http.post(
      `${environment.apiUrl}/TraineeCourse/MarkLessonIsCompleted?lessonId=${lessonId}&CourseId=${courseId}`,
      null
    );
  }
  calculatePercentageQuiz(id) {
    return this.http.get(
      `${environment.apiUrl}/TraineeCourse/CalculatePercentageQuiz?QuizId=${id}`
    );
  }
  getCourseStreamToken(id) {
    return this.http.get(
      `${environment.apiUrl}/Course/GetCourseStreamToken?CourseId=${id}`
    );
  }
  GoLive(id) {
    return this.http.put(`${environment.apiUrl}/Course/GoLive?CourseId=${id}`, null);
  }
  GoOffline(id) {
    return this.http.put(`${environment.apiUrl}/Course/GoOffline?CourseId=${id}`, null);
  }
  getPrePostAssessmentQuiz(filter) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/GetPrePostAssessmentQuiz`, {
      params: filter,
    });
  }

  getPreOrPostAssessmentGraphOfData(params) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/GetPreOrPostAssessmentGraphOfData`, { params });
  }

  getTraineePreAssessmentReportCard(id: number) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/GetTraineePreAssessmentReportCard?CourseId=${id}`);
  }

  getTraineePostAssessmentReportCard(id: number) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/GetTraineePostAssessmentReportCard?CourseId=${id}`);
  }

  calculateTraineeScoreChanges(id: number) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/CalculateTraineeScoreChanges?CourseId=${id}`);
  }

  calculateUserScoreChangesPerSlide(id: number) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/CalculateUserScoreChangesPerSlide?CourseId=${id}`);
  }

  getFinalAssessmentGraph(id: number) {
    return this.http.get(`${environment.apiUrl}/TraineeCourse/GetFinalAssessmentGraph?CourseId=${id}`);
  }

  activeCourse(id) {
    return this.http.put(`${environment.apiUrl}/Course/ActiveCourse?CourseId=${id}`, null);
  }
}
