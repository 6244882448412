export enum IndicatorActionName {
  CREATE_CASE = 'CREATE_CASE',
  DELETE_CASE = 'DELETE_CASE',
  SUBMIT_CASE = 'SUBMIT_CASE',
  CANCEL_SUBMIT_CASE = 'CANCEL_SUBMIT_CASE',
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
  RECIEVE = 'RECIEVE',

  ADD_SLIDE = 'ADD_SLIDE',
  UPDATE_SLIDE = 'UPDATE_SLIDE',
  DELETE_SLIDE = 'DELETE_SLIDE',

  REQUEST_SC_ONLY = 'REQUEST_SC_ONLY',
  ACCEPT_SC_ONLY = 'ACCEPT_SC_ONLY',
  CANCEL_SC_ONLY = 'CANCEL_SC_ONLY',
  REJECT_SC_ONLY = 'REJECT_SC_ONLY',

  REQUEST_SC_AND_PROCESS = 'REQUEST_SC_AND_PROCESS',
  ACCEPT_SC_AND_PROCESS = 'ACCEPT_SC_AND_PROCESS',
  CANCEL_SC_AND_PROCESS = 'CANCEL_SC_AND_PROCESS',
  REJECT_SC_AND_PROCESS = 'REJECT_SC_AND_PROCESS',


  REQUEST_DIAGNOSE_ONLY = 'REQUEST_DIAGNOSE_ONLY',
  ACCEPT_DIAGNOSE_ONLY = 'ACCEPT_DIAGNOSE_ONLY',
  CANCEL_DIAGNOSE_ONLY = 'CANCEL_DIAGNOSE_ONLY',
  REJECT_DIAGNOSE_ONLY = 'REJECT_DIAGNOSE_ONLY',

  PATH_REJECT_CASE = 'PATH_REJECT_CASE',
  PATH_ACCEPT_CASE = 'PATH_ACCEPT_CASE',

  MALIGNANT_REPORT = 'MALIGNANT_REPORT',
  BENIGN_REPORT = 'BENIGN_REPORT',
  OTHER_REPORT = 'OTHER_REPORT',

  TISSUE = 'TISSUE'

}
