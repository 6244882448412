<div class="card_statistic h-100 mb-0 d-flex flex-column justify-content-between w-100 {{customClassCard}}"
  #ContainerRef [ngClass]="{'card_statistic_pie':type === 'pie'}">
  <h2 class="title_card">{{ title }}</h2>
  <div class="card_inner" #InnerRef>
    <ng-container *ngIf="type === 'multi'">
      <ngx-charts-bar-vertical-2d [maxXAxisTickLength]="10" [roundDomains]="true" [barPadding]="0" [groupPadding]="5"
        [view]="[InnerRef.offsetWidth, 500]" [scheme]="colorScheme" [results]="values" [gradient]="gradient"
        [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="legend" [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
        [legendPosition]="legendPosition" [legendTitle]="legendTitle" (select)="onSelect($event)">
      </ngx-charts-bar-vertical-2d>
    </ng-container>

    <ngx-charts-pie-chart class="pie_chart" *ngIf="type === 'pie'" [view]="[ContainerRef.offsetWidth + 30, 500]"
      [scheme]="colorScheme" [results]="values" [gradient]="gradient" [legend]="legend"
      [legendPosition]="legendPosition" [labels]="showLabels" [doughnut]="isDoughnut" [legendTitle]="legendTitle"
      [labelFormatting]="formatDataLabel" (select)="onSelect($event)">
    </ngx-charts-pie-chart>

    <ng-container *ngIf="type === 'bar' || !type">
      <ngx-charts-bar-vertical [maxXAxisTickLength]="10" [roundDomains]="true" [view]="[InnerRef.offsetWidth, 500]"
        [scheme]="colorScheme" [results]="values" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
        [legend]="legend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel" [legendPosition]="legendPosition" [legendTitle]="legendTitle"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical>
    </ng-container>
    <ng-container *ngIf="type === 'horizontalbar'">
      <ngx-charts-bar-horizontal [maxXAxisTickLength]="10" [roundDomains]="false" [view]="[InnerRef.offsetWidth, 500]"
        [scheme]="colorScheme" [results]="values" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
        [legend]="legend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel" [legendPosition]="legendPosition" [legendTitle]="legendTitle"
        (select)="onSelect($event)">
      </ngx-charts-bar-horizontal>
    </ng-container>
  </div>

</div>