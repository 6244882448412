import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SlideFormComponent } from '../slide-form/slide-form.component';
import { SlidesService } from 'src/app/services/slides.service';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListData, PagedData } from 'src/app/models/slides/ListData';
import { ListFilter } from 'src/app/models/slides/ListFilter';
import { Details } from 'src/app/models/cases/Details';
import { CasesService } from 'src/app/services/cases.service';
import { RolesEnum } from 'src/app/models/users/roles/enums/roles';
import { PathologistStatus } from 'src/app/models/cases/enums/PathologistStatus';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { PrintQrcodeComponent } from '../print-qrcode/print-qrcode.component';
import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { DstatusList } from 'src/app/models/requests/enums/DstatusList';
import { ModelsEnum } from 'src/app/models/users/users/enums/ModelsEnum';
import { statusList } from 'src/app/models/cases/enums/statusList';
import { Pathologiest } from 'src/app/models/cases/Pathologiest';
import { CaselogProcedure } from 'src/app/models/cases/enums/CaselogProcedure';
import { ReadSlide } from 'src/app/models/slides/enums/ReadSlide';
import { ReceiveSlideQrcodeComponent } from '../receive-slide-qrcode/receive-slide-qrcode.component';
import { DigitizationRequestService } from 'src/app/services/digitizationRequest.service';
import { SwalService } from 'src/app/services/swal.service';
import { PathologistSource } from 'src/app/models/cases/enums/PathologistSource';

@Component({
  selector: 'app-slides-list',
  templateUrl: './slides-list.component.html'
})
export class SlidesListComponent implements OnInit, OnDestroy {
  @Input() entityId: number;
  @Input() case: Details;
  @Input() caseCode: string;
  @Input() pathologistId: number;
  @Input() requestId: number;
  @Input() pathologistType: string;
  @Input() communicationMethods: string;
  @Input() caseIdType: CaseType;
  @Input() IsEndorsement: string;
  @Input() canAssignedPathologists: boolean;
  @Input() canUpdateSlides: boolean;
  @Input() canCreateSlide: boolean;
  @Input() isRequest: boolean;
  @Input() selectedPathologiest: Pathologiest;
  @Output() refreshDetails = new EventEmitter<boolean>();
  @Input() isReferred: boolean;
  @Input() isCaseSentBack: boolean;
  @Input() canAddSlideReference: boolean;
  enableEditingUpdates: boolean;
  dataLoading = false;
  // table
  @Input() pageIndex: number;
  pageCount = 0;
  recordsData: ListData = new ListData();
  filterModel: ListFilter = new ListFilter();
  disabledActions: boolean;
  @Output() paginationEvent = new EventEmitter<number>();
  private subscriptions: Subscription[] = [];
  excludedStatuses = [
    PathologistStatus.WITH_DRAW,
    PathologistStatus.REJECTED,
    PathologistStatus.REPORTED,
    PathologistStatus.CANCELLED,
    PathologistStatus.REVIEWED
  ];

  constructor(
    public dialog: MatDialog,
    private service: SlidesService,
    private caseService: CasesService,
    public translate: TranslateService,
    private auth: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private requestService: DigitizationRequestService,
    private swalService: SwalService
  ) { }

  ngOnInit(): void {
    console.log(this.IsEndorsement)
    console.log(this.pathologistId)
    console.log(this.selectedPathologiest)
    console.log(this.case)
    this.caseService.disabledActions.subscribe(data => {
      this.disabledActions = data;
    });
    this.caseService.enableEditingUpdates.subscribe(data => {
      this.enableEditingUpdates = data;
    });
    this.subscriptions.push(
      this.service.openForm.subscribe(data => {
        if (data && (this.caseCode === data.caseIdNumber || !data.caseIdNumber)) {
          if (data && data.casesSlidesListDTO && data.casesSlidesListDTO.slideId) {
            this.openFormUpdate(data.casesSlidesListDTO);
          } else if (data && (!data.casesSlidesListDTO || (data.casesSlidesListDTO && !data.casesSlidesListDTO.slideId))) {
            this.openForm(data.srn);
          }
        }
      })
    );
    this.service.refreshSlidesList.subscribe(data => {
      if (data) {
        this.getAll(null);
      }
    });
    this.filterModel.PageSize = 6;
    this.getAll(this.pageIndex);
  }

  getAll(event) {
    this.dataLoading = true;
    if (event) {
      if (this.isRequest) {
        this.pageIndex = event;
      } else {
        this.pageIndex = Math.ceil(this.case.validSlideRefsCount / this.filterModel.PageSize) - 1 >= event ? event : event - 1;
      }
    } else {
      this.pageIndex = 0;
    }
    this.filterModel.PageNumber = this.pageIndex + 1;
    this.paginationEvent.emit(this.pageIndex);
    this.filterModel.CaseId = this.entityId;
    if (this.requestId && this.isRequest) {
      this.filterModel.dRequestId = this.requestId;
    }
    if (this.pathologistId) {
      this.filterModel.UserId = this.pathologistId;
      this.filterModel.UserType = this.pathologistType;
    }
    this.filterModel.caseIdType = this.caseIdType;
    if (
      this.case.sentBackDRequest &&
      (
        (
          this.hasPermission(`${RolesEnum.SuperAdmin},${RolesEnum.VLAdmin},${RolesEnum.VLTechnician}`) &&
          !('userId' in this.activatedRoute.snapshot.queryParams)
        ) ||
        this.hasPermission(`${RolesEnum.ScanningCenterAdmin},${RolesEnum.SCTechnician}`)
      )
    ) {
      this.filterModel.SentBackRequestId = this.case.sentBackDRequest.id;
    }
    this.service.getAll(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as ListData;
          this.pageCount = Math.ceil(this.recordsData.casesCount / this.filterModel.PageSize);
        }
      },
      error => {
        this.dataLoading = false;
      });
  }

  counter(i: number) { return new Array(i); }

  receiveQrcode() {
    const dialogRef = this.dialog.open(ReceiveSlideQrcodeComponent, {
      width: '550px',
      data: {
        readSlideEnum: this.caseIdType && this.caseIdType === CaseType.RefCase ? ReadSlide.REF_CASE : ReadSlide.CASE,
        caseCode: this.case.caseCode,
        multible: false,
        extrnalScan: false,
        isReferred: this.isReferred,
        title: 'Scan QR to Add Slide'
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (
          (
            this.caseIdType === CaseType.RefCase &&
            res.caseRequestId &&
            res.caseRequestId === this.entityId
          ) ||
          (
            this.caseIdType === CaseType.Case &&
            res.caseId &&
            res.caseId === this.entityId
          )
        ) {
          if (res && res.casesSlidesListDTO && res.casesSlidesListDTO.slideId) {
            this.openFormUpdate(res.casesSlidesListDTO);
          } else if (res && (!res.casesSlidesListDTO || (res.casesSlidesListDTO && !res.casesSlidesListDTO.slideId))) {
            this.openForm(res.srn);
          }
        }
      }
    });
  }

  checkCanAddOrEdit() {
    if (this.canUpdateSlides || this.canCreateSlide) {
      return true;
    } else {
      return false;
    }
  }

  openForm(generatedSlideReference) {
    if (!this.checkCanAddOrEdit()) {
      return;
    }
    const dialogRef = this.dialog.open(SlideFormComponent, {
      width: '550px',
      disableClose: true,
      data: {
        recordId: null,
        record: null,
        caseId: this.entityId,
        count: this.recordsData.casesCount,
        communicationMethods: this.communicationMethods,
        userId: this.pathologistId,
        userType: this.pathologistType,
        caseCode: this.case.caseCode,
        patientName: this.case.patient ? this.case.patient.patientName : this.case.patientName,
        slideNumber: generatedSlideReference,
        SentBackRequestId: this.case.sentBackDRequest ? this.case.sentBackDRequest.id : null,
        caseIdType: this.caseIdType,
        ReferenceNumber: generatedSlideReference,
        forGrossing: this.case.forGrossing,
        tissueType: this.case.tissueType
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.caseService.refreshDetails.next(true);
        if (this.isRequest) {
          this.requestService.refreshDetails.next(true);
        }
      }
    });
  }

  openFormUpdate(item) {
    if (!this.checkCanAddOrEdit()) {
      return;
    }
    const dialogRef = this.dialog.open(SlideFormComponent, {
      width: '550px',
      disableClose: true,
      data: {
        recordId: item.slideId,
        record: item,
        caseId: this.entityId,
        count: this.recordsData.casesCount,
        communicationMethods: this.communicationMethods,
        userId: this.pathologistId,
        userType: this.pathologistType,
        caseCode: this.case.caseCode,
        patientName: this.case.patient ? this.case.patient.patientName : this.case.patientName,
        slideNumber: item.generatedSlideReference,
        SentBackRequestId: this.case.sentBackDRequest ? this.case.sentBackDRequest.id : null,
        caseIdType: this.caseIdType,
        ReferenceNumber: item.generatedSlideReference,
        communicationMethodType: item.communicationMethodType,
        forGrossing: this.case.forGrossing,
        tissueType: this.case.tissueType
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAll(null);
      }
    });
  }

  deleteRecord(recordID) {
    const obj = {
      CaseSlideId: recordID,
    } as any;
    /* if (this.pathologistId && this.pathologistType) {
      obj.userId = this.pathologistId;
      obj.userType = this.pathologistType;
    } */
    obj.CaseId = this.caseIdType === CaseType.RefCase ? this.case.caseRequestId : this.case.caseId;
    obj.CaseIDType = this.caseIdType;
    // if (!this.isRequest) {
    //   obj.CaseId = this.caseIdType === CaseType.RefCase ? this.case.caseRequestId : this.case.caseId;
    //   obj.CaseIDType = this.caseIdType;
    // }
    if (this.case.sentBackDRequest) {
      obj.SentBackRequestId = this.case.sentBackDRequest ? this.case.sentBackDRequest.id : null;
    }
    this.swalService.swalConfirmation(this.translate.instant('swalMsg.Yesdeleteit'))
      .then((result) => {
        if (result.value) {
          this.dataLoading = true;
          this.service.deleteRecord(obj).subscribe(
            data => {
              // this.dataLoading = false;
              this.swalService.swalSuccess()
                .then(() => {
                  this.caseService.refreshDetails.next(true);
                  if (this.isRequest) {
                    this.requestService.refreshDetails.next(true);
                  }
                  this.getAll(null);
                });
            },
            error => {
              this.dataLoading = false;
              this.swalService.swalFailed(error.title);
            });
        }
      });
  }

  deleteSlideReference(recordID) {
    this.swalService.swalConfirmation(this.translate.instant('swalMsg.Yesdeleteit'))
      .then((result) => {
        if (result.value) {
          this.dataLoading = true;
          const data = {
            CaseId: this.caseIdType === CaseType.RefCase ? this.case.caseRequestId : this.case.caseId,
            CaseIdType: this.caseIdType,
            SlideReferenceId: recordID
          }
          this.service.deleteSlideReference(data).subscribe(
            data => {
              // this.dataLoading = false;
              this.swalService.swalSuccess()
                .then(() => {
                  this.caseService.refreshDetails.next(true);
                  if (this.isRequest) {
                    this.requestService.refreshDetails.next(true);
                  }
                  this.getAll(null);
                });
            },
            error => {
              this.dataLoading = false;
              this.swalService.swalFailed(error.title);
            });
        }
      });
  }

  removeRecord(recordID) {
    this.swalService.swalConfirmation('Yes, remove it!')
      .then((result) => {
        if (result.value) {
          this.dataLoading = true;
          const record = {
            slideId: recordID,
            caseId: this.case.caseId,
            userId: this.pathologistId,
            userType: this.pathologistType,
            status: PathologistStatus.TECHNICIAN_HIDE_SLIDE,
            SentBackRequestId: this.case.sentBackDRequest ? this.case.sentBackDRequest.id : null
          };
          this.service.showHideSlide(record).subscribe(
            data => {
              // this.dataLoading = false;
              this.swalService.swalSuccess()
                .then(() => {
                  this.caseService.refreshDetails.next(true);
                  if (this.isRequest) {
                    this.requestService.refreshDetails.next(true);
                  }
                  this.getAll(null);
                });
            },
            error => {
              this.dataLoading = false;
              this.swalService.swalFailed(error.title);
            });
        }
      });
  }

  hasPermission(permission: string) {
    return this.auth.hasPermission(permission);
  }

  printQrcode(qrToken, generatedSlideReference) {
    const dialogRef = this.dialog.open(PrintQrcodeComponent, {
      width: '350px',
      data: {
        record: {
          qrToken,
          generatedSlideReference
        }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }


  canRemoveSlide(status) {
    if (
      (
        status === PathologistStatus.ACTIVE ||
        status === PathologistStatus.TECHNICIAN_ADDED_OTHER_SLIDE ||
        status === PathologistStatus.SUPERVISOR_APPROVED_SLIDES
      ) &&
      this.selectedPathologiest &&
      (
        (
          (
            this.selectedPathologiest.status === PathologistStatus.SENT_BACK ||
            this.selectedPathologiest.status === PathologistStatus.REJECTED_PASS_PATHOLOGISTS
          ) &&
          (
            !this.case.sentBackDRequest ||
            (
              this.case.sentBackDRequest &&
              (
                this.case.sentBackDRequest.dRequestStatus === DstatusList.REJECTED ||
                this.case.sentBackDRequest.dRequestStatus === DstatusList.CANCELLED ||
                this.case.sentBackDRequest.dRequestStatus === DstatusList.SUPERVISOR_APPROVED_SLIDES
              )
            )
          )
        ) ||
        (
          (
            (
              this.selectedPathologiest.status === PathologistStatus.SUPERVISOR_APPROVED_SLIDES &&
              this.case.pathologiests.filter(pathologist => !this.excludedStatuses.includes(pathologist.status)).length === 1 &&
              (
                (
                  this.getDiffDate(
                    null,
                    this.selectedPathologiest.status,
                    'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES
                  ) &&
                  this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                ) ||
                (
                  this.getDiffDate(
                    null,
                    PathologistStatus.UPDATES_SUBMITTED,
                    'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES
                  ) &&
                  this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                ) ||
                (
                  (
                    this.getDiffDate(
                      this.case.slidesLatestModifiedDate,
                      this.selectedPathologiest.status,
                      'reverse', PathologistStatus.UPDATES_SUBMITTED
                    ) ||
                    this.getDiffDate(
                      this.case.caseLatestModifiedDate,
                      this.selectedPathologiest.status,
                      'reverse', PathologistStatus.UPDATES_SUBMITTED
                    )
                  ) &&
                  this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
                ) ||
                this.enableEditingUpdates
              )
            ) ||
            this.selectedPathologiest.status === PathologistStatus.SENT_BACK ||
            this.selectedPathologiest.status === PathologistStatus.REJECTED_PASS_PATHOLOGISTS ||
            this.selectedPathologiest.status === PathologistStatus.REQUEST_REJECTED ||
            (
              this.selectedPathologiest.status === PathologistStatus.UPDATES_SUBMITTED &&
              (
                this.getDiffDate(
                  this.case.slidesLatestModifiedDate,
                  this.selectedPathologiest.status,
                  'reverse', this.selectedPathologiest.status
                ) ||
                this.getDiffDate(
                  this.case.caseLatestModifiedDate,
                  this.selectedPathologiest.status,
                  'reverse', this.selectedPathologiest.status
                ) ||
                this.enableEditingUpdates
              )
            )
          ) &&
          (
            !this.case.sentBackDRequest ||
            (
              this.case.sentBackDRequest &&
              (
                (
                  this.case.sentBackDRequest.dRequestStatus === DstatusList.REJECTED &&
                  this.getDiffDate(
                    this.case.slidesLatestModifiedDate,
                    this.selectedPathologiest.status,
                    null, this.selectedPathologiest.status
                  )
                ) ||
                this.case.sentBackDRequest.dRequestStatus === DstatusList.CANCELLED ||
                (
                  this.case.sentBackDRequest.dRequestStatus === DstatusList.SUPERVISOR_APPROVED_SLIDES &&
                  this.case.caseLog[0].userType === ModelsEnum.LabUser &&
                  (
                    this.getDiffDate(
                      null,
                      this.selectedPathologiest.status,
                      'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES
                    ) ||
                    this.getDiffDate(
                      null,
                      this.selectedPathologiest.status,
                      'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES
                    ) ||
                    this.enableEditingUpdates
                  )
                )
              )
            )
          )
        )
      ) &&
      (
        this.hasPermission(`${RolesEnum.LabAdmin},${RolesEnum.LabTechnician},${RolesEnum.SuperAdmin}`) ||
        (
          this.hasPermission(`${RolesEnum.HospitalAdmin},${RolesEnum.HospitalCoordinator},${RolesEnum.ClinicAdmin},${RolesEnum.ClinicCoordinator}`) &&
          this.case.hasScanners
        )
      )
    ) {
      return true;
    } else if (
      (
        status === PathologistStatus.ACTIVE ||
        status === PathologistStatus.TECHNICIAN_ADDED_OTHER_SLIDE
      ) &&
      this.selectedPathologiest &&
      !this.case.dRequest &&
      !this.case.sentBackDRequest &&
      (
        (
          this.selectedPathologiest.status === PathologistStatus.SUPERVISOR_APPROVED_SLIDES &&
          this.case.pathologiests.filter(pathologist => !this.excludedStatuses.includes(pathologist.status)).length === 1 &&
          (
            (
              this.getDiffDate(
                null,
                this.selectedPathologiest.status,
                'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES
              ) &&
              this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
            ) ||
            (
              this.getDiffDate(
                null,
                PathologistStatus.UPDATES_SUBMITTED,
                'reverse', DstatusList.SUPERVISOR_APPROVED_SLIDES
              ) &&
              this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
            ) ||
            (
              (
                this.getDiffDate(
                  this.case.slidesLatestModifiedDate,
                  this.selectedPathologiest.status,
                  'reverse', PathologistStatus.UPDATES_SUBMITTED
                ) ||
                this.getDiffDate(
                  this.case.caseLatestModifiedDate,
                  this.selectedPathologiest.status,
                  'reverse', PathologistStatus.UPDATES_SUBMITTED
                )
              ) &&
              this.case.caseLog[0].procedure !== CaselogProcedure.UPDATES_SUBMITTED
            ) ||
            this.enableEditingUpdates
          )
        ) ||
        this.selectedPathologiest.status === PathologistStatus.SENT_BACK ||
        this.selectedPathologiest.status === PathologistStatus.REJECTED_PASS_PATHOLOGISTS ||
        (
          this.selectedPathologiest.status === PathologistStatus.UPDATES_SUBMITTED &&
          (
            this.getDiffDate(
              this.case.slidesLatestModifiedDate,
              this.selectedPathologiest.status,
              'reverse', this.selectedPathologiest.status
            ) ||
            this.getDiffDate(
              this.case.caseLatestModifiedDate,
              this.selectedPathologiest.status,
              'reverse', this.selectedPathologiest.status
            ) ||
            this.enableEditingUpdates
          )
        )
      ) &&
      this.hasPermission(`${RolesEnum.SuperAdmin},${RolesEnum.VLAdmin},${RolesEnum.VLTechnician}`)
    ) {
      return true;
    } else {
      return false;
    }
  }

  canUpdateSlideAuther(record: PagedData) {
    if (
      record && record.creatorType === ModelsEnum.LabUser &&
      this.case.caseStatus === statusList.SupervisorRejectUploadedSlides &&
      (
        (
          this.hasPermission(RolesEnum.SuperAdmin) &&
          this.caseIdType !== CaseType.RefCase
        ) ||
        this.hasPermission(`${RolesEnum.LabAdmin},${RolesEnum.LabTechnician}`)
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  canRemoveSlideReference(record): boolean {
    if (
      (
        this.canUpdateSlideAuther(record)
        || (
          (this.canUpdateSlides || this.canAddSlideReference) && (!this.isCaseSentBack || (this.isCaseSentBack && record.canEdit))
        )
        || this.canCreateSlide
      )
      && !this.isRequest
      && (
        this.caseIdType !== CaseType.RefCase ||
        this.canAddSlideReference
      )
      && !record.slideId
      && this.case.caseStatus !== statusList.CaseHasRejectedRequest
    ) {
      return true;
    }
  }

  getDiffDate(date, status, type = null, procedure = null) {
    return this.caseService.getDiffDate({ case: this.case, date, status, type, procedure });
  }

  slideViewed(record) {
    console.log(this.recordsData.pagedData);
    this.service.IsSlideViewed(record.slideId).subscribe((res) => {
      this.swalService.swalSuccess()
      this.getAll(null);
    }, (error) => {

    })

  }

  isPrimaryPathologist(): boolean {
    if (!this.case || this.requestId) { return }
    let pathologistList = this.case.pathologiests.concat(this.case.verificationPathologists || []);
    if (this.case.currentVerificationPathologist) {
      pathologistList.push(this.case.currentVerificationPathologist);
    }
    if (
      this.case &&
      pathologistList &&
      pathologistList.length &&
      pathologistList.some(path => path.source === PathologistSource.PRIMARY && path.isAssignedPathologist)
    ) {
      return true;
    }
  }

  canTakeActionToggle(): boolean {
    if (!this.case || this.requestId) { return }
    let pathologistList = this.case.pathologiests.concat(this.case.verificationPathologists || []);
    if (this.case.currentVerificationPathologist) {
      pathologistList.push(this.case.currentVerificationPathologist);
    }
    if (
      this.case &&
      pathologistList &&
      pathologistList.length &&
      pathologistList.some(path =>
        path.source === PathologistSource.PRIMARY &&
        path.isAssignedPathologist &&
        !this.excludedStatuses.includes(path.status) &&
        path.status !== PathologistStatus.SENT_BACK &&
        path.status !== PathologistStatus.ACTIVE
      )
    ) {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.service.openForm.next(null);
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
