import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-with-search',
  templateUrl: './select-with-search.component.html'
})
export class SelectWithSearchComponent implements OnInit,OnChanges {
  @Input() formGroup: FormGroup;
  @Input() sourcData:any[];
  @Input() isSubmitted:boolean;
  @Input() controlName:any
  @Input() fieldLabel:String;
  //
  @ViewChild('searchInput', { static: false })
  searchInput: ElementRef<HTMLInputElement>;
  //
  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
   if(changes && changes.isSubmitted && changes.isSubmitted.currentValue){
    this.formGroup.controls[this.controlName].markAllAsTouched();
   }
  }

  applyFilter(value: string) {
    const filterValue = value.toLowerCase();
    this.sourcData.forEach((item) => {
      item.hidden = true;
      if (filterValue === '') {
        item.hidden = false;
      } else if (item.name.toLowerCase().includes(filterValue)) {
        item.hidden = false;
      }
    });
  }
  openEvent(opened) {
    if (opened) {
      this.searchInput.nativeElement.focus();
    } else {
      this.searchInput.nativeElement.value = '';
      this.applyFilter('');
    }
  }
  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }
}
