export enum RolesEnum {
  SuperAdmin = 'SuperAdmin',
  LabAdmin = 'LabAdmin',
  MainLabAdmin = 'MainLabAdmin',
  LabSupervisor = 'LabSupervisor',
  LabTechnician = 'LabTechnician',
  LabCoordinator = 'LabCoordinator',
  LabAccountant = 'LabAccountant',
  LabRepresentative = 'LabRepresentative',
  LabTechnologist = 'LabTechnologist',
  LabQualityManager = 'LabQualityManager',

  Pathologist = 'Pathologist',
  ScanningCenterAdmin = 'ScanningCenterAdmin',
  ScanningCenterMainAdmin = 'ScanningCenterMainAdmin',
  SCTechnician = 'ScanningCenterTechnician',
  Radiologiest = 'Radiologiest',
  MatchMaker = 'MatchMaker',
  ScanningCenterAccountant = 'ScanningCenterAccountant',
  ScanningCenterRepresentative = 'ScanningCenterRepresentative',

  VLAdmin = 'VLAdmin',
  VLMatchMaker = 'VLMatchMaker',
  VLPathologist = 'VLPathologist',
  VLTechnician = 'VLTechnician',
  VLSupervisor = 'VLSupervisor',
  bc = 'bc',
  VLRepresentative = 'VLRepresentative',
  VLAccountant = 'VLAccountant',
  VLabTechnologist = 'VLabTechnologist',
  VLabQualityManager = 'VLabQualityManager',

  HospitalAdmin = 'HospitalAdmin',
  HospitalRepresentative = 'HospitalRepresentative',
  HospitalCoordinator = 'HospitalCoordinator',
  HospitalAccountant = 'HospitalAccountant',

  ClinicAdmin = 'ClinicAdmin',
  ClinicAccountant = 'ClinicAccountant',
  ClinicRepresentative = 'ClinicRepresentative',
  ClinicCoordinator = 'ClinicCoordinator',

  Service = 'Service',

  Trainer = 'Trainer',
  Trainee = 'Trainee'
}

/* VLAdmin
VLTechnician
VLPathologist
Pathologist */
